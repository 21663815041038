document.addEventListener('DOMContentLoaded', () => {

  const url = document.querySelector('.js-time-slot').dataset.ajaxRoute;
  let clientTzOffset = new Date().getTimezoneOffset() * -1; // On veut savoir combien de minutes ajouter ou enlever à UTC

  fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      'clientTzOffset': clientTzOffset.toString(),
    }),
    headers: new Headers({
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }),
  })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        body: text,
      })),
    )
    .then(({ status, body }) => {
      if (200 === status) {
        const data = JSON.parse(body);

        // Afficher
        document.querySelector('.home-recipe__time').textContent = data['title'];
        document.querySelector('.home-recipe__title-description').textContent = data['userTime'];
        document.querySelector('.home-recipe__container').innerHTML = data['markup'];
      }
    });
});
